
import UserConfigurationModal from '@/components/modals/UserConfigurationModal';
import users from '../services/users';
export default {
  components: {
    UserConfigurationModal
  },
  props: {},

  data() {
    return {
      typingTimer:null,
      // pages: 10,
      // paginationCount:1,
      // perPage: 10,
      // current: 1,
      openModel: false,
      visible: false,
      pagination: {
        page: 1,
        rowsPerPage: 10
      },
      loading: false,
      userDetails: '',
      selected: [],
      confirm: false,
      selectedUser: '',
      filter: '',
      rows: [],
      rows2: [],
      rows3: [],
      executeOnce: true,
      loadAllData: true,
      columns: [
        {
          name: 'title',
          align: 'left',
          label: this.$store.state.user.translate.name,
          field: row => row.profile.fullName,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: 'email',
          label: this.$store.state.user.translate.email,
          field: 'email'
        },
        {
          name: 'role',
          label: this.$store.state.user.translate.role,
          field: 'role'
        },
        {
          name: 'actions',
          label: this.$store.state.user.translate.actions,
          field: 'actions'
        }
      ]
    };
  },
  // computed: {
  //   pagesNumber() {
  //     return Math.ceil(this.rows.length / this.pagination.rowsPerPage);
  //   }
  // },
  watch: {
    filter: {
      handler(newVal, oldVal) {
        clearTimeout(this.typingTimer);
          this.typingTimer = setTimeout(() => {
          this.pagination.page = 1;
          this.requestAllUsers();
        }, 2000);
      }
    }
  },
  created() {
    // this.userCounter();
    this.visible = true;
    this.requestAllUsers();
    this.requestUserDetails();
  },
  // mounted() {
  //     this.visible = true
  //     this.requestAllUsers()
  //     this.requestUserDetails()
  // },
  methods: {
    searchMethod(){
      clearTimeout(this.typingTimer);
      this.pagination.page = 1;
      this.requestAllUsers();
    },
    closeModel() {
      this.openModel = false;
      this.loading = false;
    },
    // async userCounter() {
    //   await users.getCounter().then((res) => {
    //     if (res.data.response.count) {
    //       this.pages = Math.ceil(res.data.response.count / this.perPage);
    //     }
    //   });
    // },
    async updateUserProfile(opt) {
      this.loading = true;
      this.closeModel();
      this.requestAllUsers(this.current);
      // if(!opt){
      //   this.requestAllUsers(this.current);
      // }else{
      //   this.current = this.pages
      //  this.requestAllUsers(this.pages);
      // }
    },
    async requestAllUsers() {
      // let query = !search ? skip * this.perPage - this.perPage : '';
      this.visible = true;
      this.loading = true;
      let params = {
        pageNumber: this.pagination.page,
        perPage: this.pagination.rowsPerPage,
        ...(this.filter && { searchText: this.filter })
      };
      await users
        .getUsers(params)
        .then(response => {
          this.rows = response.data.response.users;
          //  if (search) {
          //   this.rows2 = this.rows
          // } else {
          //   this.rows3 = this.rows;
          // }
          this.pagination.pagesNumber = Math.ceil(
            response.data.response.totalCount / this.pagination.rowsPerPage
          );
          this.visible = false;
          this.loading = false;
        })
        .catch(error => {
          this.visible = false;
          this.loading = false;
          this.loadAllData = true;
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: 'red'
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: 'red'
            });
          }
        });
    },

    requestUserDetails() {
      users.getUserDetails().then(response => {
        this.userDetails = response.data.response.userDetails;
      });
    },
    deleteUser(row) {
      this.selectedUser = row;
      this.confirm = true;
    },

    async deleteSelectedUser() {
      users
        .deleteUser(this.selectedUser._id)
        .then(() => {
          this.requestAllUsers(this.current);
          this.$q.notify({
            message: this.$store.state.user.translate.deleted_successfully,
            color: 'green'
          });
          this.confirm = false;
        })
        .catch(error => {
          // console.log(error)
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: 'red'
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: 'red'
            });
          }
          this.confirm = false;
        });
    },
    addNewUser() {
      this.selectedUser = null;
      this.openModel = !this.openModel;
    },
    editUserDialog(user) {
      this.selectedUser = user;
      this.openModel = !this.openModel;
    }
  }
};
